export default ()=> {
  let arrow =  '<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.2071 8.7069C19.5977 8.31639 19.5977 7.68322 19.2072 7.29269L12.8433 0.928595C12.4528 0.538062 11.8196 0.538048 11.4291 0.928565C11.0386 1.31908 11.0386 1.95225 11.4291 2.34278L17.0858 7.99975L11.4288 13.6565C11.0383 14.047 11.0383 14.6802 11.4288 15.0707C11.8193 15.4612 12.4525 15.4612 12.843 15.0707L19.2071 8.7069ZM0.999813 8.99941L18.5 8.99978L18.5001 6.99978L0.999855 6.99941L0.999813 8.99941Z" fill="white"/></svg>'
  $('.symptoms-slider__for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        infinite: false,
        asNavFor: '.symptoms-slider__nav',
        nextArrow: '<button type="button" class="slick-next slick-arrow symptoms-slider__arrow symptoms-slider__arrow--next">'+arrow+'</button>',
        prevArrow: '<button type="button" class="slick-prev slick-arrow symptoms-slider__arrow symptoms-slider__arrow--prev">'+arrow+'</button>',
      });
      $('.symptoms-slider__nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.symptoms-slider__for',
        focusOnSelect: true,
        centerPadding: "0px",
      });
}


