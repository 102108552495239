import './helpers/postDate';
import scrollSmooth from './helpers/smooth-scroll.js';
import symptoms from './modules/symptoms.js';
import result from './modules/result.js';
import review from './modules/review.js';
import reviewForm from './modules/review-form.js';

function main() {
  scrollSmooth();
  symptoms();
  result();
  review();
  reviewForm();
}

if (document.documentElement.clientWidth < 480) {
  window.addEventListener('scroll',
    function () {
      return setTimeout(main, 1000);
    }, {
      once: true
    });
} else {
  main();
};
