export default ()=>{
    let wrap = document.querySelector('.review__block')
    wrap.addEventListener('click', function(e){
        console.log(e.target)
        if (e.target.classList.contains('review-grade__like') || e.target.classList.contains('review-grade__dislike')) {
            if (!e.target.parentElement.classList.contains('review-grade__item--active')) {
                e.target.parentElement.classList.add('review-grade__item--active')
                e.target.nextElementSibling.innerText = +e.target.nextElementSibling.innerText + 1
                if (!!e.target.parentElement.nextElementSibling && e.target.parentElement.nextElementSibling.classList.contains('review-grade__item--active')) {
                    e.target.parentElement.nextElementSibling.classList.remove('review-grade__item--active')
                    let count = e.target.parentElement.nextElementSibling.querySelector('.review-grade__count')
                    count.innerText = +count.innerText - 1
                }
                if (!!e.target.parentElement.previousElementSibling && e.target.parentElement.previousElementSibling.classList.contains('review-grade__item--active')) {
                    e.target.parentElement.previousElementSibling.classList.remove('review-grade__item--active')
                    let count = e.target.parentElement.previousElementSibling.querySelector('.review-grade__count')
                    count.innerText = +count.innerText - 1
                }
            } else {
                e.target.parentElement.classList.remove('review-grade__item--active')
                e.target.nextElementSibling.innerText = +e.target.nextElementSibling.innerText - 1
            }
        } 

        if (e.target.classList.contains('review-item__more') && !e.target.classList.contains('review-item__more--open')) {
            e.target.classList.add('review-item__more--open')
            e.target.previousElementSibling.classList.add('review-item__text--open')
            e.target.innerText = e.target.dataset.open
        } else if (e.target.classList.contains('review-item__more--open')) {
            e.target.classList.remove('review-item__more--open')
            e.target.previousElementSibling.classList.remove('review-item__text--open')
            e.target.innerText = e.target.dataset.close
        }
    })
}
